import service from "@/api/service";
import {
  GetRequest,
  SortRequest,
  RegisterRequest,
  DeleteRequest
} from "@/api/image-shop/request";
import { GetResponse } from "@/api/image-shop/response";
import { CommonResponse } from "@/api/response";

/**
 * 公開画像情報取得APIをコールします。
 *
 * @return GetResponse
 */
export async function get(getRequest: GetRequest) {
  const response = await service.post("/get-image-shop", getRequest);
  return response.data as GetResponse;
}

/**
 * 公開画像情報ソートAPIをコールします。
 *
 * @param sortRequest
 * @return CommonResponse
 */
export async function sort(sortRequest: SortRequest) {
  const response = await service.post("/sort-image-shop", sortRequest);
  return response.data as CommonResponse;
}

/**
 * 公開動画登録更新APIをコールします。
 *
 * @param registerRequest
 * @return CommonResponse
 */
export async function register(registerRequest: RegisterRequest) {
  const response = await service.post("/regist-image-shop", registerRequest);
  return response.data as CommonResponse;
}

/**
 * 公開画像削除APIをコールします。
 *
 * @param deleteRequest
 * @return CommonResponse
 */
export async function deleteOne(deleteRequest: DeleteRequest) {
  const response = await service.post("/delete-image-shop", deleteRequest);
  return response.data as CommonResponse;
}
