import { Component, Mixins, Prop } from "vue-property-decorator";
import UIFormRow from "@/components/UIFormRow.vue";
import UlFileRegister from "@/components/UlFileRegister.vue";
import { UlFileRegisiterTabType } from "@/utils/enums";
import RedirectWithAlert from "@/models/mixins/redirect-with-alert";
import CategoryGet from "@/store/category/get";
import StateGet from "@/store/state/get";
import MainShopGet from "@/store/main-shop/get";
import ShopDetail from "@/store/shop/detail";
import ShopModify from "@/store/shop/modify";
import Admin from "@/store/admin/admin";
import Flash, {ErrorAlert} from "@/store/common/flash";
import Loading from "@/store/loading";
import { DetailResult } from "@/api/shop/response";
import { RegisterRequest } from "@/api/shop/request";
import flash from "@/store/common/flash";
import _ from "lodash";
import WindowSizeChecker from "@/utils/window-size-checker";
import { LoginStorage } from "@/store/login-storage";
import ShopAgreementAPI from "@/store/shop-agreement/get";
import {GetRequest, GetRequest as GetAgreementRequest} from "@/api/shop-agreement/request";

import ItemNameShopGet from "@/store/item-name-shop/get";
import { GetResult } from "@/api/item-name-shop/response";
import NewEdit from "@/models/shop-manage/new-edit";

@Component({ components: { UIFormRow, UlFileRegister } })
export default class NewEditBasic extends Mixins(RedirectWithAlert) {
  @Prop({ type: String, required: false })
  id?: string | null;

  @Prop({ type: Object, required: false })
  agreements: {} | undefined;

  initImageUrl = null as string | null;
  allowedTypes = ["image/jpeg", "image/png", "image/gif"];
  appInitImageUrl = null as string | null;
  appAllowedTypes = ["image/png"];
  faviInitImageUrl = null as string | null;
  faviAllowedTypes = ["image/png"];

  tabType = UlFileRegisiterTabType.Icon;

  menuProps = {
    closeOnClick: false,
    closeOnContentClick: false,
    disableKeys: true,
    openOnClick: false,
    maxHeight: 304
  };

  // 契約状態（基本情報）
  agreementsBasic = {
    ap: false,
    line: false,
    web: false
  };

  // PC推奨サイズ
  imageSizePc = {
    width: 2320,
    height: 1548
  }

  // アプリ推奨サイズ
  imageSizeApp = {
    width: 1160,
    height: 774
  }

  imageFileSizePc = 50;
  imageFileSizeApp = 50;

  pixelSizeDescription = null as string | null;
  fileSizeDescription = null as string | null;

  inputParams = {
    /** 店舗ID */
    id: null as string | null,
    /** 本部ID */
    mainShopId: null as string | null,
    /** 店舗説明文 */
    description: null as string | null,
    /** キャッチコピー */
    catchcopy: null as string | null,
    /** メインイメージ画像の編集フラグ */
    imageEditFlg: 0 as 0 | 1,
    /** メインイメージ画像パス */
    imagePath: null as string | null,
    /** t_shop_image_histories.id */
    imageId: null as number | null,
    /** 管理画面用のファビコンの編集フラグ */
    faviconEditFlg: 0 as 0 | 1,
    /** 管理画面用のファビコン */
    favicon: null as string | null,
    /** t_shop_image_histories.id */
    faviconImageId: null as number | null,
    /** 営業時間 */
    businessHours: null as string | null,
    /** 定休日 */
    holiday: null as string | null,
    /** 座席数 */
    seat: null as string | null,
    /** 駐車場 */
    parkInfo: null as string | null,
    /** 料金目安 */
    budget: null as string | null,
    /** 予約ページURL */
    reserveUrl: null as string | null,
    /** その他 */
    other: null as string | null,
    /** カテゴリの利用する/しない（API返却にはない）*/
    isCategory: 0,
    /** カテゴリID */
    categoryId: [] as string[],
    /** カテゴリID */
    categoryIdObjs: [{ value: null as string | null }],
    /** GoogleアナリティクストラッキングID */
    gaTrackingId: null as string | null,
    /** 問い合わせ先メールアドレス */
    inquiryMail: null as string | null,
    /** 実績レポート配信先メールアドレス */
    reportMail: [] as string[] | null,
    /** 実績レポート配信先メールアドレス */
    reportMailObjs: [{ value: null as string | null }],
  };

  inputItemParams: GetResult = {
    id: null,
    shopId: Number(this.id),
    cmsShopName: null,
    cmsShopNameKana: null,
    cmsState: null,
    cmsAddress1: null,
    cmsAddress2: null,
    cmsTel: null,
    cmsBusinessHours: null,
    cmsHoliday: null,
    cmsSeat: null,
    cmsParkInfo: null,
    cmsBudget: null,
    cmsReserveUrl: null,
    cmsOther: null,
    cmsInquiryMail: null,
    spShopName: null,
    spAddress: null,
    spTel: null,
    spBusinessHours: null,
    spHoliday: null,
    spSeat: null,
    spParkInfo: null,
    spBudget: null,
    spOther: null,
    spInquiryMail: null
  };

  get isEdit() {
    return !!this.id;
  }

  get isAdmin() {
    return Admin.isAdmin;
  }

  get isTabletAndPC() {
    return WindowSizeChecker.isTabletAndPC(this);
  }

  get categoryItems() {
    return CategoryGet.getItems;
  }

  get stateItems() {
    return StateGet.getItems;
  }

  get isCategorySettable() {
    return this.isEdit;
  }

  get loading() {
    return Loading.isLoading;
  }

  get kbn() {
    return LoginStorage.getKBN();
  }

  /**
   * 契約が Web のみか否かの判定
   * @return true:契約が Web のみ false:契約が Web のみでない
   */
  isWebAgreementOnly(): boolean {
    const result = this.agreementsBasic.ap === false && this.agreementsBasic.line === false && this.agreementsBasic.web === true;
    return result;
  }

  /**
   * 契約が未登録かの判定
   * @return true:契約が 未登録 false:契約が 登録済
   */
  isNotAgreement(): boolean {
    const result = this.agreementsBasic.ap === false && this.agreementsBasic.line === false && this.agreementsBasic.web === false;
    return result;
  }

  fileDescription() {
    if (this.agreementsBasic.web === true) {
      this.pixelSizeDescription = "※お店のロゴやイメージ画像を登録して下さい。<br/>※推奨サイズ：<span class='red--text' >横幅" + this.imageSizePc.width + "px 縦幅" + this.imageSizePc.height + "px</span>（推奨サイズ以上の場合、PC画面での表示が崩れる場合があります。）";
      this.fileSizeDescription = "※容量：" + this.imageFileSizePc + "KB以下";
    } else {
      this.pixelSizeDescription = "※お店のロゴやイメージ画像を登録して下さい。<br/>※推奨サイズ：<span class='red--text' >横幅" + this.imageSizeApp.width + "px 縦幅" + this.imageSizeApp.height + "px</span>（推奨サイズ以上の場合、アプリ画面での表示が崩れる場合があります。）";
      this.fileSizeDescription = "※容量：" + this.imageFileSizeApp + "KB以下";
    }
  }

  async created() {
    await Admin.restore();

    let getErrorMessage = null as string | null;
    const mainShop = MainShopGet.get();
    const state = StateGet.get();
    await Promise.all([mainShop, state]);
    if (!StateGet.isSuccess) {
      getErrorMessage = StateGet.getMessage;
    }
    if (getErrorMessage) {
      await Flash.setErrorNow({
        message: getErrorMessage,
        showReloadButton: true
      });
      return;
    }

    if (this.isEdit) {
      await CategoryGet.get({ id: Number(this.id) });
      if (!CategoryGet.isSuccess) {
        await Flash.setErrorNow({
          message: CategoryGet.getMessage,
          showReloadButton: true
        });
      }
      await ShopDetail.detail({ id: this.id!! });
      if (!ShopDetail.isSuccess) {
        await Flash.setErrorNow({
          message: ShopDetail.getMessage,
          showReloadButton: true
        });
        return;
      }
      this.syncInputParams(ShopDetail.getDetail);
      // 契約情報を取得する
      const requestAgrement = {
        shopId: this.id
      } as GetRequest;
      await ShopAgreementAPI.get(requestAgrement);
      if (!ShopAgreementAPI.isSuccess) {
        await Flash.setErrorNow({
          message: "",
          showReloadButton: true
        } as ErrorAlert);
        return;
      } else {
        const AgreementItems = ShopAgreementAPI.getagreementsList;
        AgreementItems.forEach((item: { agreementsType: number }) => {
          if (item.agreementsType == 1) {
            this.agreementsBasic.ap = true;
          } else if (item.agreementsType == 2) {
            this.agreementsBasic.line = true;
          } else if (item.agreementsType == 3) {
            this.agreementsBasic.web = true;
          }
        });
      }
    }

    await ItemNameShopGet.get({ id: Number(this.id) });
    if (!ItemNameShopGet.isSuccess) {
      await Flash.setErrorNow({
        message: ItemNameShopGet.getMessage,
        showReloadButton: true
      });
      return;
    }
    this.inputItemParams = ItemNameShopGet.getResult;

    this.fileDescription();
  }

  setFile(data: string, id: number | null) {
    this.inputParams.imageEditFlg = 1;
    this.inputParams.imagePath = data;
    this.inputParams.imageId = id;
  }

  get mainShopItems() {
    //NEW_DEV-1604 cyber start
    if (!MainShopGet.isSuccess) {
      return [
        {
          id: ShopDetail.getDetail["mainShopId"],
          name: ShopDetail.getDetail["mainShopName"]
        }
      ];
    }
    //NEW_DEV-1604 cyber end
    return MainShopGet.getItems;
  }

  unsetFile() {
    this.inputParams.imageEditFlg = 1;
    this.inputParams.imagePath = null;
    this.inputParams.imageId = null;
  }

  setFaviconFile(data: string, id: number | null) {
    this.inputParams.faviconEditFlg = 1;
    this.inputParams.favicon = data;
    this.inputParams.faviconImageId = id;
  }

  unsetFaviconFile() {
    this.inputParams.faviconEditFlg = 1;
    this.inputParams.favicon = null;
    this.inputParams.faviconImageId = null;
  }

  showCategoryAdd(index: number) {
    const categoryLength = this.inputParams.categoryIdObjs.length;
    return categoryLength === index + 1 && categoryLength < 10;
  }

  addCategory(index: number) {
    this.inputParams.categoryIdObjs.splice(index + 1, 0, { value: null });
  }

  removeCategory(index: number) {
    this.inputParams.categoryIdObjs.splice(index, 1);
    if (this.inputParams.categoryIdObjs.length <= 0) {
      this.inputParams.categoryIdObjs.push({ value: null });
    }
  }

  showReportMailAdd(index: number) {
    const reportMailLength = this.inputParams.reportMailObjs.length;
    return reportMailLength === index + 1 && reportMailLength < 5;
  }

  addReportMail(index: number) {
    this.inputParams.reportMailObjs.splice(index + 1, 0, { value: null });
  }

  removeReportMail(index: number) {
    this.inputParams.reportMailObjs.splice(index, 1);
    if (this.inputParams.reportMailObjs.length <= 0) {
      this.inputParams.reportMailObjs.push({ value: null });
    }
  }

  goBack() {
    this.$router.back();
  }

  async goForward() {
    await flash.clear();
    await ShopModify.register(this.createRegisterRequest());
    if (ShopModify.isSuccess) {
      await this.redirectWithSuccessAlert(
        "店舗を保存しました。",
        "/shop-management"
      );
    } else {
      await flash.setErrorNow({
        message: ShopModify.getMessage,
        showReloadButton: false
      });
    }
  }

  private syncInputParams(shopDetail: DetailResult) {
    this.inputParams = { ...this.inputParams, ...shopDetail };
    if (shopDetail.categoryId && shopDetail.categoryId.length > 0) {
      this.inputParams.categoryIdObjs = shopDetail.categoryId.map(val => {
        return { value: val };
      });
    }
    if (shopDetail.reportMail && shopDetail.reportMail.length > 0) {
      this.inputParams.reportMailObjs = shopDetail.reportMail.map(val => {
        return { value: val };
      });
    }
    if (shopDetail.imagePath) {
      this.initImageUrl = shopDetail.imagePath;
    }
    if (shopDetail.favicon) {
      this.faviInitImageUrl = shopDetail.favicon;
    }
  }

  private createRegisterRequest() {
    const registerRequest = _.cloneDeep(this.inputParams);

    const categoryIds = [] as string[];
    for (let category of this.inputParams.categoryIdObjs) {
      if (category.value) {
        categoryIds.push(category.value);
      }
    }
    registerRequest.categoryId = categoryIds;
    delete registerRequest.categoryIdObjs;
    if (registerRequest.isCategory === 0) {
      delete registerRequest.categoryId;
    }

    const reportMails = [] as string[];
    for (let reportMail of this.inputParams.reportMailObjs) {
      reportMails.push(reportMail.value ? reportMail.value : "");
    }
    registerRequest.reportMail = reportMails;
    delete registerRequest.reportMailObjs;

    if (registerRequest.imageId != null || registerRequest.imageEditFlg === 0) {
      delete registerRequest.imagePath;
    }
    if (registerRequest.faviconImageId != null || registerRequest.faviconEditFlg === 0) {
      delete registerRequest.favicon;
    }
    return registerRequest as RegisterRequest;
  }
}
