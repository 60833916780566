import service from "@/api/service";
import { GetResponse } from "@/api/link-type/response";

/**
 * 外部サイト区分取得APIをコールします。
 *
 * @return GetResponse
 */
export async function get() {
  const response = await service.post("/get-link-type");
  return response.data as GetResponse;
}
