import { Component, Mixins, Prop } from "vue-property-decorator";
import UIFormRow from "@/components/UIFormRow.vue";
import RedirectWithAlert from "@/models/mixins/redirect-with-alert";
import MovieShopGet from "@/store/movie-shop/get";
import MovieShopModify from "@/store/movie-shop/modify";
import Flash from "@/store/common/flash";
import { RegisterRequest } from "@/api/movie-shop/request";
import { MovieItem } from "@/api/movie-shop/response";
import Loading from "@/store/loading";
import WindowOpen from "@/utils/window-open";

@Component({ components: { UIFormRow } })
export default class NewEditMovies extends Mixins(RedirectWithAlert) {
  @Prop({ type: String, required: false })
  id!: string | null;

  inputParams = {
    movieItems: [
      {
        id: null as number | null,
        shopId: Number(this.id),
        moviePath: null as string | null,
        movieComment: null,
        sort: 0
      } as MovieItem
    ]
  };

  get loading() {
    return Loading.isLoading;
  }

  get shopName() {
    return this.$route.query.shopName ? this.$route.query.shopName : "";
  }

  async created() {
    await MovieShopGet.get({ id: Number(this.id) });
    if (!MovieShopGet.isSuccess) {
      await Flash.setErrorNow({
        message: MovieShopGet.getMessage,
        showReloadButton: true
      });
      return;
    }
    if (MovieShopGet.getItems.length > 0) {
      this.inputParams.movieItems = MovieShopGet.getItems;
    }
  }

  showMovieAdd(index: number) {
    return (
      index === this.inputParams.movieItems.length - 1 &&
      this.inputParams.movieItems.length < 10
    );
  }

  addMovieUrl(index: number) {
    this.inputParams.movieItems.splice(index + 1, 0, {
      id: null as number | null,
      shopId: Number(this.id),
      moviePath: null as string | null,
      movieComment: null,
      sort: 0
    } as MovieItem);
  }

  removeMovieUrl(index: number) {
    this.inputParams.movieItems.splice(index, 1);
    if (this.inputParams.movieItems.length <= 0) {
      this.inputParams.movieItems.push({
        id: null as number | null,
        shopId: Number(this.id),
        moviePath: null as string | null,
        movieComment: null,
        sort: 0
      } as MovieItem);
    }
  }

  goBack() {
    this.$router.back();
  }

  async preview() {
    await this.callRegisterApi(1);
    if (MovieShopModify.isSuccess) {
      WindowOpen.preview(MovieShopModify.getPreviewUrl);
    }
  }

  async onSave() {
    await this.callRegisterApi(0);
    if (MovieShopModify.isSuccess) {
      await this.redirectWithSuccessAlert(
        "公開動画設定を保存しました。",
        "/shop-management"
      );
    }
  }

  private async callRegisterApi(isPreview: number) {
    await Flash.clear();
    await MovieShopModify.register(this.createRegisterRequest(isPreview));
    if (!MovieShopModify.isSuccess) {
      await Flash.setErrorNow({
        message: MovieShopModify.getMessage,
        showReloadButton: false
      });
    }
  }

  private createRegisterRequest(isPreview: number) {
    const request = { shopId: Number(this.id) } as RegisterRequest;
    request.id = this.inputParams.movieItems.map(item => item.id);
    request.isPreview = isPreview;
    request.moviePath = this.inputParams.movieItems.map(item => item.moviePath);
    request.movieComment = this.inputParams.movieItems.map(
      item => item.movieComment
    );
    request.sort = this.inputParams.movieItems.map((_, index) => index + 1);

    return request;
  }
}
