import { Component, Mixins, Prop } from "vue-property-decorator";
import RedirectWithAlert from "@/models/mixins/redirect-with-alert";
import UIDataTable from "@/components/UIDataTable.vue";
import UIDialogDelete from "@/components/UlDialogDelete.vue";
import UlFileRegister from "@/components/UlFileRegister.vue";
import UIFormRow from "@/components/UIFormRow.vue";
import ImageShopGet from "@/store/image-shop/get";
import ImageShopModify from "@/store/image-shop/modify";
import Flash, { ErrorAlert, SuccessAlert } from "@/store/common/flash";
import { TableOptions } from "@/api/request";
import { ImageItem } from "@/api/image-shop/response";
import {
  DeleteRequest,
  RegisterRequest,
  SortRequest
} from "@/api/image-shop/request";
import Sortable from "sortablejs";
import { UlFileRegisiterTabType } from "@/utils/enums";
import ImageShopPreviewGet from "@/store/image-shop-preview/get";
import { GetRequest } from "@/api/image-shop-preview/request";
import WindowOpen from "@/utils/window-open";
import ShopAgreementAPI from "@/store/shop-agreement/get";
import {GetRequest as GetAgreementRequest} from "@/api/shop-agreement/request";

@Component({
  components: { UIDataTable, UIDialogDelete, UlFileRegister, UIFormRow }
})
export default class NewEditImages extends Mixins(RedirectWithAlert) {
  @Prop({ type: String, required: false })
  id!: string | null;

  @Prop({ type: Object, required: false })
  agreements: {} | undefined;

  @Prop({ type: Function, required: false })
  isWebAgreementOnly: Function | undefined;

  @Prop({ type: Function, required: false })
  isNotAgreement: Function | undefined;

  isLoading = false;
  tableOptions = {} as TableOptions;
  tableHeaders = [
    { text: "", value: "reorder", always: true, sortable: false },
    { text: "コメント", value: "imageComment", sortable: false },
    { text: "リンク名", value: "imageLinkName", sortable: false },
    { text: "遷移先URL", value: "imageLink", sortable: false },
    { text: "画像", value: "image", sortable: false },
    {
      label: "削除",
      text: "",
      value: "delete",
      sortable: false
    },
    {
      label: "修正",
      text: "",
      value: "edit",
      sortable: false
    }
  ];

  // 登録ダイアログの表示有無
  showRegisterDialog = false;

  registerParams: RegisterRequest = {
    id: null,
    shopId: Number(this.id),
    imageComment: null,
    imageLinkName: null,
    imageLink: null,
    imageEditFlg: 0,
    imagePath: "",
    imageId: null
  };

  initImageUrl = null as string | null;
  allowedTypes = ["image/jpeg", "image/png", "image/gif"];
  tabType = UlFileRegisiterTabType.Photo;

  // 削除ダイアログの表示有無
  showDeleteDialog = false;

  // 削除されるタイトル
  deletingTitle = "";

  // 削除されるメッセージID
  deletingId = 0 as number;

  // 契約状態（公開画像）
  agreementsImages = {
    ap: false,
    line: false,
    web: false
  };

  // PC推奨サイズ
  imageSizePc = {
    width: 520,
    height: 520
  }

  // アプリ推奨サイズ（縦表示）
  imageSizeVerticalApp = {
    width: 362,
    height: 440
  }

  // アプリ推奨サイズ（横表示）
  imageSizeHorizontalApp = {
    width: 722,
    height: 390
  }

  // アプリ推奨サイズ（正方形表示）
  imageSizeSquareApp = {
    width: 230,
    height: 230
  }

  imageFileSizePc = 50;
  imageFileSizeApp = 50;

  pixelSizeDescription = null as string | null;
  fileSizeDescription = null as string | null;

  get tableItems() {
    return ImageShopGet.getItems;
  }

  get shopName() {
    return this.$route.query.shopName ? this.$route.query.shopName : "";
  }

  fileDescription() {
    if (this.agreementsImages.web === true) {
      this.pixelSizeDescription = "※推奨サイズ：<span class='red--text' >横幅" + this.imageSizePc.width + "px 縦幅" + this.imageSizePc.height + "px</span><br/>（推奨サイズ以上の場合、PC画面での表示が崩れる場合があります。）";
      this.fileSizeDescription = "※容量：" + this.imageFileSizePc + "KB以下";
    } else {
      this.pixelSizeDescription = "※推奨サイズ：縦表示の場合、<span class='red--text' >横幅" + this.imageSizeVerticalApp.width + "px 縦幅"
          + this.imageSizeVerticalApp.height + "px</span><br/>　横表示の場合、<span class='red--text' >横幅" + this.imageSizeHorizontalApp.width
          + "px 縦幅" + this.imageSizeHorizontalApp.height + "px</span><br/>　正方形表示の場合、<span class='red--text' >横幅"
          + this.imageSizeSquareApp.width + "px 縦幅" + this.imageSizeSquareApp.height
          + "px</span><br/>（推奨サイズ以上の場合、アプリ画面での表示が崩れる場合があります。）";
      this.fileSizeDescription = "※容量：" + this.imageFileSizeApp + "KB以下";
    }
  }

  async created() {
    await this.getList();
    // 契約情報を取得する
    const requestAgrement = {
      shopId: this.id
    } as GetAgreementRequest;
    await ShopAgreementAPI.get(requestAgrement);
    if (!ShopAgreementAPI.isSuccess) {
      await Flash.setErrorNow({
        message: "",
        showReloadButton: true
      } as ErrorAlert);
      return;
    } else {
      const AgreementItems = ShopAgreementAPI.getagreementsList;
      AgreementItems.forEach((item: { agreementsType: number }) => {
        if (item.agreementsType == 1) {
          this.agreementsImages.ap = true;
        } else if (item.agreementsType == 2) {
          this.agreementsImages.line = true;
        } else if (item.agreementsType == 3) {
          this.agreementsImages.web = true;
        }
      });
    }
    this.fileDescription();
  }

  /**
   * mountedライフサイクルフック（UIDataTableをソート可能にする）
   */
  mounted() {
    this.$nextTick(() => {
      const _self = this;

      const mainTable = document.querySelector(
        ".v-data-table tbody"
      ) as HTMLElement;
      Sortable.create(mainTable, {
        onEnd({ newIndex, oldIndex }) {
          const rowSelected = _self.tableItems.splice(oldIndex as number, 1)[0];
          _self.tableItems.splice(newIndex as number, 0, rowSelected);
          _self.sort().then();
        }
      });
    });
  }

  setFile(data: string, id: number | null) {
    this.registerParams.imageEditFlg = 1;
    this.registerParams.imagePath = data;
    this.registerParams.imageId = id;
  }

  unsetFile() {
    this.initImageUrl = null;
    this.registerParams.imageEditFlg = 1;
    this.registerParams.imagePath = null;
    this.registerParams.imageId = null;
  }

  tableChangeCallback(tableOptions: TableOptions) {}

  editClickCallback(item: ImageItem) {
    //NEW_DEV-1800 cyber start
    this.registerParams.imageEditFlg = 0;
    //NEW_DEV-1800 cyber end
    this.showRegisterDialog = true;
    this.registerParams = {
      ...this.registerParams,
      ...item
    };
    this.initImageUrl = item.imagePath;
  }

  deleteClickCallback(item: ImageItem) {
    this.showDeleteDialog = true;
    this.deletingTitle = "選択した公開画像";
    this.deletingId = item.id;
  }

  async doDeleteOnDialog() {
    await Flash.clear();
    this.showDeleteDialog = false;
    if (this.deletingId <= 0) {
      return;
    }

    this.isLoading = true;
    await ImageShopModify.deleteOne({
      id: this.deletingId,
      shopId: Number(this.id)
    } as DeleteRequest);
    if (ImageShopModify.isSuccess) {
      await Flash.setSuccessNow({
        message: "公開画像を削除しました。",
        consumePath: ""
      } as SuccessAlert);
      await ImageShopGet.get({ id: Number(this.id) });
    } else {
      await Flash.setErrorNow({
        message: ImageShopModify.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    this.isLoading = false;
  }

  goBack() {
    this.$router.back();
  }

  async webPreview() {
    await ImageShopPreviewGet.get({
      shopId: Number(this.id),
      isPreview: 2
    } as GetRequest);
    if (ImageShopPreviewGet.isSuccess) {
      WindowOpen.webPreview(ImageShopPreviewGet.getPreviewUrl);
    } else {
      await Flash.setErrorNow({
        message: ImageShopPreviewGet.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
  }

  async preview() {
    await ImageShopPreviewGet.get({
      shopId: Number(this.id),
      isPreview: 1
    } as GetRequest);
    if (ImageShopPreviewGet.isSuccess) {
      WindowOpen.preview(ImageShopPreviewGet.getPreviewUrl);
    } else {
      await Flash.setErrorNow({
        message: ImageShopPreviewGet.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
  }

  onNew() {
    this.showRegisterDialog = true;
    this.registerParams = {
      id: null,
      shopId: Number(this.id),
      imageComment: null,
      imageLinkName: null,
      imageLink: null,
      imageEditFlg: 0,
      imagePath: "",
      imageId: null
    };
    this.initImageUrl = null;
  }

  /**
   * 並び替え処理
   */
  async sort() {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;
    await ImageShopModify.sort(this.createSortRequest());
    if (!ImageShopModify.isSuccess) {
      await Flash.setErrorNow({
        message: ImageShopModify.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    this.isLoading = false;
  }

  async onRegister() {
    await Flash.clear();
    this.isLoading = true;
    this.initImageUrl = this.registerParams.imagePath;
    this.showRegisterDialog = false;
    if (
      this.registerParams.imageEditFlg === 0 ||
      this.registerParams.imageId != null
    ) {
      delete this.registerParams.imagePath;
    }
    await ImageShopModify.register(this.registerParams);
    if (ImageShopModify.isSuccess) {
      await this.getList();
    } else {
      await Flash.setErrorNow({
        message: ImageShopModify.getMessage,
        showReloadButton: false
      });
    }
    this.isLoading = false;
  }

  async getList() {
    this.isLoading = true;
    await ImageShopGet.get({ id: Number(this.id) });
    if (!ImageShopGet.isSuccess) {
      await Flash.setErrorNow({
        message: ImageShopGet.getMessage,
        showReloadButton: true
      });
    }
    this.isLoading = false;
  }

  private createSortRequest() {
    const request = { shopId: Number(this.id) } as SortRequest;
    request.ids = this.tableItems.map(i => i.id);
    return request;
  }
}
