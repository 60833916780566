import { Component, Mixins, Prop } from "vue-property-decorator";
import UIFormRow from "@/components/UIFormRow.vue";
import RedirectWithAlert from "@/models/mixins/redirect-with-alert";
import LinkTypeGet from "@/store/link-type/get";
import LinkShopGet from "@/store/link-shop/get";
import LinkShopModify from "@/store/link-shop/modify";
import Flash from "@/store/common/flash";
import { RegisterRequest } from "@/api/link-shop/request";
import Loading from "@/store/loading";
import WindowOpen from "@/utils/window-open";
import Config from "@/store/config/get";

@Component({ components: { UIFormRow } })
export default class NewEditLinks extends Mixins(RedirectWithAlert) {
  @Prop({ type: String, required: false })
  id!: string | null;

  @Prop({ type: Object, required: false })
  agreements: {} | undefined;

  @Prop({ type: Function, required: false })
  isNotAgreement: Function | undefined;

  imgHelp = 'help.png';

  menuProps = {
    closeOnClick: false,
    closeOnContentClick: false,
    disableKeys: true,
    openOnClick: false,
    maxHeight: 304
  };

  inputParams = {
    twitterFlg: 0,
    twitter: null as null | string,
    twitterApiKey: null as null | string,
    twitterAccessToken: null as null | string,
    twitterApiKeySecret: null as null | string,
    twitterAccessTokenSecret: null as null | string,
    twitterPostFlg: 0,
    twitterPost: null as null | string,
    facebookFlg: 0,
    facebook: null as null | string,
    facebookAppId: null as null | string,
    facebookPageId: null as null | string,
    facebookAccessToken: null as null | string,
    facebookPostFlg: 0,
    facebookPost: null as null | string,
    instagramFlg: 0,
    instagram: null as null | string,
    instagramPostFlg: 0,
    instagramPost: null as null | string,
    googleBizProfFlg: 0,
    googleBizProfPostFlg: 0,
    googleBizProfLink: null as null | string,
    googleBizProfConfig: null as null | string,
    googleBizProfTokenInfo: null as null | string,
    snsInfoLinkFlg: 0,
    snsPostLinkFlg: 0,
    links: [
      {
        linkId: null as null | number,
        linkType: null as null | string,
        linkPath: null as null | string
      }
    ]
  };

  get loading() {
    return Loading.isLoading;
  }

  get linkTypes() {
    return LinkTypeGet.getItems;
  }

  get shopName() {
    return this.$route.query.shopName ? this.$route.query.shopName : "";
  }

  get getConfig() {
    return Config.getItems;
  }

  async created() {
    await LinkTypeGet.get();
    await LinkShopGet.get({ id: Number(this.id) });
    await Config.get();
    if (!LinkShopGet.isSuccess) {
      await Flash.setErrorNow({
        message: LinkShopGet.getMessage,
        showReloadButton: true
      });
      return;
    }
    this.inputParams = LinkShopGet.getResult;
    if (this.inputParams.links.length <= 0) {
      this.inputParams.links.push({
        linkId: null,
        linkType: null,
        linkPath: null
      });
    }
  }

  addOtherLink(index: number) {
    this.inputParams.links.splice(index + 1, 0, {
      linkId: null,
      linkType: null,
      linkPath: null
    });
  }

  removeOtherLink(index: number) {
    this.inputParams.links.splice(index, 1);
    if (this.inputParams.links.length <= 0) {
      this.inputParams.links.push({
        linkId: null,
        linkType: null,
        linkPath: null
      });
    }
  }

  goBack() {
    this.$router.back();
  }

  async preview() {
    await this.callRegisterApi(1);
    if (LinkShopModify.isSuccess) {
      WindowOpen.preview(LinkShopModify.getPreviewUrl);
    }
  }

  async webPreview() {
    await this.callRegisterApi(2);
    if (LinkShopModify.isSuccess) {
      WindowOpen.webPreview(LinkShopModify.getPreviewUrl);
    }
  }

  async onSave() {
    await this.callRegisterApi(0);
    if (LinkShopModify.isSuccess) {
      await this.redirectWithSuccessAlert(
        "外部リンク情報を保存しました。",
        "/shop-management"
      );
    }
  }

  private async callRegisterApi(isPreview: number) {
    await Flash.clear();
    await LinkShopModify.register(this.createRegisterRequest(isPreview));
    if (!LinkShopModify.isSuccess) {
      await Flash.setErrorNow({
        message: LinkShopModify.getMessage,
        showReloadButton: false
      });
    }
  }

  private createRegisterRequest(isPreview: number) {
    const request = {
      id: Number(this.id),
      twitterFlg: this.inputParams.twitterFlg,
      twitter: this.inputParams.twitter,
      twitterApiKey: this.inputParams.twitterApiKey,
      twitterAccessToken: this.inputParams.twitterAccessToken,
      twitterApiKeySecret: this.inputParams.twitterApiKeySecret,
      twitterAccessTokenSecret: this.inputParams.twitterAccessTokenSecret,
      twitterPostFlg: this.inputParams.twitterPostFlg,
      facebookFlg: this.inputParams.facebookFlg,
      facebook: this.inputParams.facebook,
      facebookAppId: this.inputParams.facebookAppId,
      facebookPageId: this.inputParams.facebookPageId,
      facebookAccessToken: this.inputParams.facebookAccessToken,
      facebookPostFlg: this.inputParams.facebookPostFlg,
      instagramFlg: this.inputParams.instagramFlg,
      instagram: this.inputParams.instagram,
      instagramPostFlg: this.inputParams.instagramPostFlg,
      googleBizProfFlg: this.inputParams.googleBizProfFlg,
      googleBizProfLink: this.inputParams.googleBizProfLink,
      googleBizProfConfig: this.inputParams.googleBizProfConfig,
      googleBizProfTokenInfo: this.inputParams.googleBizProfTokenInfo,
      googleBizProfPostFlg: this.inputParams.googleBizProfPostFlg,
      isPreview: isPreview
    } as RegisterRequest;
    if (request.twitterFlg !== 1) {
      delete request.twitter;
    }
    if (request.facebookFlg !== 1) {
      delete request.facebook;
    }
    if (request.instagramFlg !== 1) {
      delete request.instagram;
    }
    if (request.googleBizProfFlg !== 1) {
      delete request.googleBizProfLink;
      delete request.googleBizProfConfig;
    }
    request.linkId = this.inputParams.links.map(link => link.linkId);
    request.linkType = this.inputParams.links.map(link => link.linkType);
    request.linkPath = this.inputParams.links.map(link => link.linkPath);
    request.linkSort = this.inputParams.links.map((_, index) => index + 1);
    return request;
  }
}
