import { render, staticRenderFns } from "./NewEditNames.vue?vue&type=template&id=0ede491c&"
import script from "../../models/shop-manage/new-edit-names.ts?vue&type=script&lang=ts&"
export * from "../../models/shop-manage/new-edit-names.ts?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports