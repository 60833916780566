import { render, staticRenderFns } from "./NewEditLinks.vue?vue&type=template&id=6597ce8a&scoped=true&"
import script from "../../models/shop-manage/new-edit-links.ts?vue&type=script&lang=ts&"
export * from "../../models/shop-manage/new-edit-links.ts?vue&type=script&lang=ts&"
import style0 from "./NewEditLinks.vue?vue&type=style&index=0&id=6597ce8a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6597ce8a",
  null
  
)

export default component.exports