import { render, staticRenderFns } from "./NewEditMovies.vue?vue&type=template&id=61f62bb8&"
import script from "../../models/shop-manage/new-edit-movies.ts?vue&type=script&lang=ts&"
export * from "../../models/shop-manage/new-edit-movies.ts?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports